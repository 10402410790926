<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  head: {
    script: [
      {
        type: 'text/css',
        src: '~/src/assets/css/colors/warning.css',
    }
    ]
  }
//   mounted() {
//     // <link href="@/assets/css/colors/default.css" rel="stylesheet" type="text/css" id="color-opt" />
//     const linkElement = document.createElement("link");
//     linkElement.setAttribute(
//       "src",
//       "@/src/assets/css/colors/default.css"
//     );
//     linkElement.setAttribute(
//       "rel",
//       "stylesheet"
//     );
//     linkElement.setAttribute(
//       "type",
//       "text/css"
//     );
    
//     linkElement.setAttribute(
//       "id",
//       "color-opt"
//     );
    
//     document.head.appendChild(linkElement);
//   }
}
</script>
